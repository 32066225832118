<template>
  <div id="dashboard-content">
    <div class="mb-4 d-flex">
      <h4>Tugas Workshop</h4>
      <router-link
        to="tugas/add"
        class="btn rounded-pill btn-secondary text-white ml-auto"
      >
        <i class="fa fa-circle-plus"></i> Setor Tugas
      </router-link>
    </div>

    <template v-if="tasks.length == 0">
      <div class="text-center mt-5">
        <img width="60" src="@/assets/not-found.png" class="img-fluid" alt="" />
        <h4 class="text-center mt-2">Data tidak ditemukan.</h4>
      </div>
    </template>
    <template v-else>
      <div v-if="$store.state.loadPage" class="loading text-center">
        <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
      </div>
      <div v-else class="card border-0 shadow-sm p-3">
        <div class="input-group mb-3 w-50">
          <div class="input-group-prepend">
            <span
              class="input-group-text bg-white border-right-0"
              id="basic-addon1"
              ><i class="fa fa-search"></i
            ></span>
          </div>
          <input
            type="text"
            class="form-control border-left-0"
            placeholder="Cari kata kunci"
            v-model="keyword"
          />
        </div>
        <div class="table-responsive">
          <table class="table table-stripped mt-1 text-center">
            <thead class="bg-grey-200">
              <tr class="rounded-pill">
                <th scope="col" class="text-left">No</th>
                <th scope="col" class="text-left">Judul</th>
                <th scope="col">Tipe</th>
                <th scope="col">Status</th>
                <th scope="col">Lampiran</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="filteredTask.length == 0">
                <td colspan="6">Pencarian tidak ditemukan</td>
              </tr>
              <tr
                v-else
                v-for="(portfolio, index) in filteredTask"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td class="text-left">{{ portfolio.title }}</td>
                <td>{{ portfolio.type }}</td>
                <td v-if="portfolio.status == 'publish'">
                  <span
                    class="badge bg-success-50 text-success px-3 py-2 rounded-pill"
                    >{{ portfolio.status }}</span
                  >
                </td>
                <td v-else>
                  <span
                    class="badge bg-secondary-50 text-secondary px-3 py-2 rounded-pill"
                    >{{ portfolio.status }}</span
                  >
                </td>
                <td align="center">
                  <a
                    :href="
                      `https://static.buku.kemdikbud.go.id/uploader/files/${portfolio.attachment}`
                    "
                    target="_blank"
                    class="text-primary-400"
                  >
                    <span class="fa fa-file mr-2"></span>
                    Lihat file
                  </a>
                </td>
                <td width="170">
                  <div class="dropright">
                    <button
                      type="button"
                      class="btn btn-sm rounded-0 bg-white"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-ellipsis fa-lg"></i>
                    </button>
                    <div class="dropdown-menu">
                      <router-link
                        :to="{
                          name: 'Tugas-Edit',
                          params: { id: portfolio.id },
                        }"
                        class="dropdown-item px-2"
                      >
                        <i
                          class="fa fa-pencil-alt rounded text-primary bg-primary-100 p-1"
                        ></i>
                        Edit
                      </router-link>
                      <span
                        role="button"
                        @click="deletePortfolio(portfolio.id)"
                        class="dropdown-item px-2"
                      >
                        <i
                          class="fa fa-trash rounded text-danger bg-danger-100 p-1"
                        ></i>
                        Hapus
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>

    <div class="row my-4 text-right">
      <div class="col-md-12">
        <nav id="paging"></nav>
        <!-- courses pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Task",
  data() {
    return {
      keyword: "",
    };
  },
  computed: {
    ...mapState(["tasks"]),
    filteredTask() {
      return this.tasks.filter((task) => {
        return task.title
          .toLowerCase()
          .includes(this.keyword.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions(["fetchAllTask", "deletePortfolioById"]),
    deletePortfolio(id) {
      const confirm = window.confirm("Apakah anda yakin akan menghapus?");
      confirm && this.deletePortfolioById(id);
    },
  },
  created() {
    this.fetchAllTask();
    this.$store.state.messageStatusPortfolio = false;
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
  },
};
</script>

<style>
#paging strong {
  padding: 12px 20px;
  margin-right: 10px;
  background-color: #ffc600;
  border-radius: 6px;
}
#paging a {
  padding: 7px 16px;
  border: 2px solid #aaaaaa;
  margin-right: 10px;
  border-radius: 6px;
  color: black;
}
</style>
